import { useEffect, useState } from 'react'
import { isUrlCurrent } from 'common/utils/urlUtils'
import { MenuItemInterface } from '../types/MenuInterface';

export default function useActiveMenuItem(item: MenuItemInterface, index: number) {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null)
  const [isSameUrl, setIsSameUrl] = useState(false)
  const [activeSubitems, setActiveSubitems] = useState<number[]>([])

  const currentUrl = useCurrentUrl()

  useEffect(() => {
    if(typeof window === 'undefined') return

    if(item.subMenuItems) {
      item.subMenuItems.forEach(({ link }, index) => {
        setActiveSubitems(prev => isUrlCurrent(link, currentUrl) ? [...prev, index] : prev)
      })

      return
    }

    setIsSameUrl(isUrlCurrent(item.link, currentUrl))
  }, [currentUrl, item.subMenuItems, item.link])

  const isItemActive = hoveredItemIndex === index || isSameUrl

  return {
    setHoveredItemIndex,
    isItemActive,
    activeSubitems,
  }
}

const useCurrentUrl = () => {
  const [url, setUrl] = useState(
    typeof window === 'undefined' ? '' : window.location.href,
  )

  const listenToPopstate = () => {
    setUrl(window.location.href)
  }

  useEffect(() => {
    window.addEventListener('popstate', listenToPopstate)
    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])

  return url
}
